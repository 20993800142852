/* Base Styles -------------------- */

h1,h2,h3,h4{
  font-style: italic;
  text-transform: uppercase;
}


.lead{
  color: #AAAAAA;
}

.color-grey{
  color: #AAAAAA;
}

.img-responsive{
  display: block;
  max-width: 100%;
  height: auto;
}


.short-text-block{
  max-width: 480px;
}


.icon-list{
  list-style: none;
  font-weight: 600;
  padding-left: 0;
  margin-left: 0;

  li{
    display: flex;
    align-items: center;
    img{
      display: inline-block;
      max-height: 15px;
      width: 18px;
      height: auto;
      margin-right: 15px;
    }
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }

}

.link-dark{
  text-decoration: underline;
  color: #000000;
  &:hover{
    text-decoration: none;
    color: #D8232A;
  }
}
