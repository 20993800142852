.hero{
  min-height: 600px;
  height: 95vh;

  background-image: url('/assets/img/hero-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  .lead{
    color: #AAAAAA;
    font-weight: 600;
    max-width: 590px;
    font-size: 1.28rem;
  }

  .display-3{

    @include media-breakpoint-down(md) {
      font-size: 3rem;

    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;

    }
  }
  .hero-scroll{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    border: none;
    bottom: 8%;
  }
}
