.detail-modal-close{
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  .close{
    color: red;
    opacity: 1;
  }
}


.detail-modal-content-scroll{
  max-height: 250px;
  overflow: scroll;
  margin: 20px 0;
}


.detail-modal-bg{
  background-image: url('/assets/img/hero-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height: 554px;


  &.bg-servis{
    background-image: url('/assets/img/modal-servis-bg.jpg');
  }
  &.bg-prohlidky{
    background-image: url('/assets/img/modal-prohlidky-bg.jpg');
  }
  &.bg-pneu{
    background-image: url('/assets/img/modal-pneu-bg.jpg');
  }

  &.bg-dily{
    background-image: url('/assets/img/modal-dily-bg.jpg');
  }

}


.detail-modal-logos {
display: block;
  height: auto;
  width: 100%;
}
