.header-contact{
 display: flex;
  align-items: center;
  margin: 0 1rem;
  font-weight: 600;
  line-height: 1.2;
  .header-contact-icon{
    display: flex;
    align-items: center;
    margin-right: 22px;
    img{
      display: block;
      width: auto;
      height: 28px;
    }


  }

  .header-contact-lead{
    color: #000;
  }

  .header-contact-link{
    text-decoration: underline;
  color: #AAAAAA;
    &:hover{
      text-decoration: none;
      color: #D8232A;
    }
  }

}
